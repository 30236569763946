import imgSeparador from '../Assets/img/Imagen-nosotros.jpg';

const Separador = ()=>{

    return(

    <div className='separadorContainer'>
        <img src={imgSeparador} alt="Felicidad" className="imgSeparador" />
    </div>
        )
}

export default Separador