import "../Styles/StyleQuienes.css";

const QuienesSomos = () => {
  return (
    <div className="quienesContainer" id="nosotros">
      <h2 className="quienesTitulo">QUIÉNES SOMOS</h2>
      <div className="QuienesParrafoContainer">
        <p className="quienes">
          <span>Centro Ava</span> brinda un servicio que responde a las
          necesidades más urgentes de las personas que padecen problemas de
          adicción. Somos una comunidad de puertas abiertas, libre de drogas y
          alcohol.
        </p>
        <p className="quienes">
          Damos tratamiento a todos nuestros residentes guiando y acompañando en
          todas sus etapas de recuperacion para lograr esa vida que merecen.
          <br />
          <span>¡Una vida libre de sustancias es posible!</span>
        </p>
        <p className="quienes quienesFinal">
          Para esto es fundamental el apoyo y acompañamiento de la familia,
          logrando asi una mejor contención en el camino de recuperación.
        </p>
      </div>
    </div>
  );
};

export default QuienesSomos;
