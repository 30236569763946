import '../Styles/StyleContacto.css'


const Contacto = ()=>{
   
    return(
        <div className='contactoContainer' id='contacto'>
            <h3>CONTACTO</h3>
            <form action="" className='formContacto'>
            <label htmlFor="nombre" className='labelForm'>Nombre*</label>
            <input type="text" placeholder="Ingresa tu nombre" className='inputForm'/>
            <hr className='hrForm'/>
            <label htmlFor="telefono" className='labelForm'>Teléfono*</label>
            <input type="number" name="teléfono" id="telefono" placeholder="Ingresa tu teléfono" className='inputForm' minlength="4" maxlength="10" size="10"/>
            <hr className='hrForm'/>
            <label htmlFor="email" className='labelForm'>E-mail*</label>
            <input type="mail" name="email" id="email" placeholder='Ingresa tu email'className='inputForm'/>
            <hr className='hrForm'/>
            <label htmlFor="mensaje" className='labelForm mensajeForm'>Mensaje*</label>
            <textarea type="text" name="mensaje" id="mensaje" className='inputForm' placeholder='Escribe tu mensaje aquí' cols='30' rows='6'></textarea>
            <hr className='hrForm'/>
            <input type="submit" value="Enviar" className='submitForm'/>
            </form>
        </div>
    )
}

export default Contacto 