import React from 'react';
import LandingPage from './components/LandingPage';
import './Styles/Normalize.css'
import './Styles/Styles.css'


const App = () => {
  return (
    <div className="App">
      <LandingPage/>
    </div>
  );
};

export default App;